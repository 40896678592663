<!-------------------------------------------------
description:  发票详情 驳回原因 详情组件
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail-plus class="box-card" :labelWidth="150">
    <div slot="header" class="clearfix">
      <span>驳回原因</span>
    </div>
    <ykc-detail-item-plus label="驳回原因" prop="name">
      <!-- <ykc-input readonly disabled v-model="info.reason" type="textarea"></ykc-input> -->
      <div>{{ info.reason || '——' }}</div>
    </ykc-detail-item-plus>
  </ykc-detail-plus>
</template>

<script>
  // import YkcInput from '@/components/ykc-ui/input/src/ykc-input.vue';
  // import YkcDetail from '@/components/ykc-ui/detail/src/main.vue';
  // import YkcDetailItem from '@/components/ykc-ui/detail-item/src/main.vue';

  export default {
    name: 'RejectReason',
    // components: { YkcInput },
    props: {
      info: { type: Object, default: () => ({}) },
    },
  };
</script>

<style scoped></style>
